@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$dmp-app-primary: mat-palette($mat-blue);
$dmp-app-accent:  mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dmp-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$dmp-app-theme: mat-light-theme($dmp-app-primary, $dmp-app-accent, $dmp-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($dmp-app-theme);

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 100%;
  background: #e1f0f5;
  color: #212121;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  @extend .typo-body-2
}

h1 {
  @extend .typo-display-4;
}

h2 {
  @extend .typo-display-3;
}

h3 {
  @extend .typo-display-2;
}

h4 {
  @extend .typo-display-1;
}

h5 {
  @extend .typo-headline;
}

.table-definition-list {
  td,
  th {
    vertical-align: top;
  }
}

.typo-display-4,
.typo-display-3,
.typo-display-2,
.typo-display-1,
.typo-headline,
.typo-title,
.typo-subheading,
.typo-body-2,
.typo-body-1,
.typo-caption,
.typo-button {
  opacity: .87;
}

.typo-display-4 {
  font-weight: 300;
  font-size: 7rem;
  line-height: 1.5;
  letter-spacing: -.04em;
  opacity: .54;
}

.typo-display-3 {
  font-size: 3.5rem;
  line-height: 1.35;
  letter-spacing: -.02em;
  opacity: .54;
}

.typo-display-2 {
  font-size: 2.8125rem;
  line-height: 1.066666em;
  opacity: .54;
}

.typo-display-1 {
  font-size: 2.125rem;
  line-height: 1.176em;
  opacity: .54;
}

.typo-headline {
  font-size: 1.5rem;
  line-height: 1.3333em;
  -moz-osx-font-smoothing: grayscale
}

.typo-title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75em;
  letter-spacing: .02em
}

.typo-subheading {
  letter-spacing: .04em
}

.typo-body-2 {
  font-weight: 500;
  font-size: .8125rem;
  line-height: 1.714em;
}

.typo-body-1 {
  font-size: .8125rem;
  line-height: 1.428em;
}

.typo-caption {
  font-size: .75rem;
  opacity: .54;
}

.typo-button {
  font-weight: 500;
  font-size: .8125rem;
  text-transform: uppercase;
}

.link-active {
  background-color: #e9e9e9;
}

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-spacer {
  flex: 1 1 auto;
  min-width: 20px;
}

button,
input,
select,
textarea {
  font-size: 1em;
}

label.mat-radio-label {
  align-items: center;

  .mat-radio-container {
    top: auto;
  }
}

.mat-icon.mat-icon-small {
  height: 12px;
  width: 12px;
  font-size: 12px;
}

.mat-input-subscript-wrapper {
  margin-top: 0;
}

.mat-slide-toggle .mat-slide-toggle-label {
  padding: 0.4375em 0;
}

mat-card-header h5 {
  margin: 0;
}

.mat-dialog-container {
  overflow: visible !important;
  position: relative;
  min-width: 600px;

  .mat-dialog-title {
    margin: 0;
  }
}

#dialog-close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.error {
  color: #660000;
  max-width: 600px;
}

.mat-card-actions,
.mat-dialog-actions {
  justify-content: flex-end;

  &:last-child {
    margin-bottom: -12px;
  }

  .mat-button,
  .mat-raised-button {
    margin-left: 1em;
  }
}

.mat-card-actions.card-form-actions {
  display: flex;
}

.mat-sidenav-content {
  background-color: #ffffff;
}

.mat-form-field {
  width: 100%;
}

.mat-autocomplete-panel {
  max-width: none !important;
}

.cdk-overlay-pane {
  width: auto !important;
}

.page-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.page-header {
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid #ccc;
  background: rgba(0, 0, 0, 0.1);
  min-height: 65px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  h4 {
    margin: 0;
  }
}

.page-content {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-button-container {
  margin-bottom: 38px;

  #btn-add-segment {
    position: absolute;
    margin-top: -28px;
    z-index: 1000;
    right: 20px;
  }
}

.site-drawer {
  background-color: #37474f;
  border: 0;

  .site-drawer-navigation .matl-navigation__link {
    color: #eceff1;

    &:hover {
      background: #2196f3;
      color: #fff;
    }
  }
}

footer {
  flex: 0 0 60px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.info-list {
  padding: 0;
  margin: 0 16px;
  list-style: none;
}

.info-list-header {
  padding-left: 10px;
  margin-bottom: 5px;
}

.info-list-body .info-list-line {
  float: left;
  width: 80%;
}

.shadowed {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
  position: relative;
  border-radius: 5px;
  margin: 8px;
}

.table-caption {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 64px;
  vertical-align: middle;
  padding: 0 14px 0 24px;

  h5 {
    margin-right: 1em;
  }
}

.table-container {
  padding: 0;
}

table.data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  tr {
    border-bottom: 1px solid #d0d0d0;
  }

  & > thead > tr {
    & > td,
    & > th {
      white-space: nowrap;
      font-size: .75rem;
      color: rgba(0, 0, 0, .54);
      user-select: none;
      cursor: default;

      mat-icon {
        vertical-align: sub;
        font-size: 1rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  & > tbody {
    & > tr {
      transition: background-color .3s ease-in;

      &:hover {
        background-color: #EEEEEE;
      }

      &.selected {
        background-color: rgba(#2196f3, .1) !important;
      }

      &.special {
        td {
          font-weight: 700;

          &:first-child {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgb(33, 150, 243);
              width: 4px;
              height: 100%;
              padding: 0;
            }
          }
        }
      }

      @extend .typo-body-1
    }
  }

  td,
  th {
    padding: 12px 12px 6px 12px;
    text-align: left;
    vertical-align: top;
    word-break: break-all
  }

  &.data-table--striped > tbody > tr:nth-child(odd) {
    background-color: #f6f6f6;

    &:hover {
      background-color: #EEEEEE;
    }
  }

  &.data-table--sortable > thead > tr > th {
    cursor: pointer;
    white-space: nowrap;

    &:after {
      width: 1em;
      content: '';
      font-family: 'Material Icons';
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      word-wrap: normal;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      vertical-align: sub;
    }

    &.sort-asc,
    &.sort-desc {
      color: rgba(0, 0, 0, .87);
    }

    &.sort-desc:after {
      content: 'arrow_downward';
    }

    &.sort-asc:after {
      content: 'arrow_upward';
    }

    &.data-table__cell--numeric {
      direction: rtl;
    }
  }

  .data-table__cell--numeric {
    text-align: right;
    white-space: nowrap;
  }

  .data-table__cell--empty {
    padding: 0;
  }

  .data-table__cell--eclipsed {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 0;
  }
}

div.code-mirror-container {
  height: 80px;
  span.ng-invalid label {
    color: #f44336;
  }
}

app-codemirror.ng-invalid.ng-dirty {
  label.mat-input-placeholder {
    color: #f44336;
  }

  div.CodeMirror {
    border: 1px solid #f44336;
  }
}

.mat-form-field.ng-invalid.ng-touched:not(.mat-form-field-disabled) .mat-input-wrapper,
.mat-form-field.ng-invalid.ng-touched:not(.mat-form-field-disabled) .mat-input-placeholder {
  color: #f44336;
}

.mat-form-field.ng-invalid.ng-touched:not(.mat-form-field-disabled) .mat-input-underline .mat-input-ripple {
  background-color: #f44336;
  height: 1px;
  opacity: 1;
  transform: scaleY(1);
}

button.create-button {
  background-color: #2196f3;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #a3a3a3;
  cursor: pointer;
}

button:disabled.create-button {
  background-color: #9e9e9e;
  cursor: default;
}

.mat-button,
.mat-mini-fab {
  &.mat-small {
    width: 20px;
    height: 20px;

    .mat-button-wrapper {
      display: block;
      padding: 0;
      line-height: 14px;

      .mat-icon {
        font-size: 14px;
        width: 14px;
        height: 14px;
        line-height: 14px;
      }
    }
  }
}
